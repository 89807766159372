<template>
  <Teleport to="body">
    <organism-static-modal modalSize="modal-xl" ref="organism-static-modal">
      <template #header-left>
        <div class="support-characters">
        {{ $t('지원문자') }} <br/>
          ABCDEFGHJKLMNOPQRSTUVWXYZ / abcdefghijklmnopqrstuvwxyz / 1234567890
        </div>
      </template>
      <template #header-right>
        <dot-button type="button" class="bg-dot-orange rounded-5" style="height:48px; font-size: 20px;"
                    @click="onConfirmButton">{{ $t('확인') }}
        </dot-button>
      </template>
      <template #body class="text">
        <div class="d-flex align-items-center input-text-padding-top justify-content-center" style="height: 40rem">
          <div class="h-100 input-text-length" style="flex-basis: 45%">
            <textarea class="w-100 h-100" v-model="inkWords"></textarea>
            <div style="flex-basis: 10%;">
              <dot-button type="button" class="bg-dot-orange w-100 h-100" @click="onClickTranslateToBraille">{{ $t('번역') }}</dot-button>
            </div>
          </div>
          <div class="icon">
            <svg width="28" height="26" viewBox="0 0 28 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0649 2.6875L26.375 13L16.0649 23.3125M1.625 13L25.6016 13" stroke="#464646" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
          </div>
          <div class="h-100" style="flex-basis: 45%; overflow-y: scroll;">
            <!-- pages -->
            <template v-if="pages.length > 0">
              <template v-for="(page, index) in pages" v-bind:key="index">
                <textarea class="w-100 three-hundred-cells-braille" v-model="pages[index]" readonly :disabled="index >= maxPage"></textarea>
                <div class="number">{{ index + 1 }}/{{ pages.length > 100 ? maxPage : pages.length }}</div>
              </template>
            </template>
            <template v-else>
              <textarea class="w-100" readonly
                        style="height: 20.6rem; font-size: 2.1rem; line-height: 1.2"></textarea>
              <div class="number">1/1</div>
            </template>
          </div>
        </div>
      </template>
    </organism-static-modal>
  </Teleport>
</template>

<script>
import { mapGetters } from "vuex";
import { Braille } from "../js/Braille";
import { DotpadSDK } from "../js/DotpadSDK";
import { DTMS, Page } from "../js/common";
import OrganismStaticModal from "@/components/Organism/OrganismStaticModal.vue";
import DotButton from "@/components/atoms/DotButton.vue";

export default {
  name: "TactileLettersModal",
  components: {
    OrganismStaticModal,
    DotButton,
  },
  data() {
    return {
      NUMBER_OF_HORIZONTAL_CELLS: 20,
      NUMBER_OF_VERTICAL_CELLS: 10,
      dotpadsdk: new DotpadSDK(),
      title: "",
      inkWords: "",
      pages: [],
    }
  },
  mounted() {
    this.$watch(
      "$refs.organism-static-modal.isShown",
      () => {
        const isShown = this.$refs["organism-static-modal"].isShown;

        if (isShown) {
          this.$parent.$parent.$parent.removeAllEventListener();
        } else {
          this.$parent.$parent.$parent.addHotkeyEventListeners();
        }
      }
    );
  },
  watch: {
    changePin(){
      switch (this.pin) {
        case 6 :
          this.blank = 2;
          break;
        case 8 :
          this.blank = 1;
          break;
        default :
          this.blank = 2;
      }
    },
  },
  computed: {
    ...mapGetters("canvasPage", ["maxPage"]),
    ...mapGetters("braille", ["kind", "language", "grade", "rule", "pin"]),
    TWO_HUNDRED_CELLS() {
      return this.NUMBER_OF_HORIZONTAL_CELLS * this.NUMBER_OF_VERTICAL_CELLS;
    },
    greatGrandParent() {
      return this.$parent.$parent.$parent;
    },
  },
  methods: {
    open() {
      this.$refs["organism-static-modal"].open();
    },
    async onClickTranslateToBraille() {
      const response = await this.fetchTextToBraille();
      let brailleUnicode;
      this.pages = []

      if (response.length === 0) {
        return;
      }

      brailleUnicode = this.brailleUnicode(response); // 점자 변환
      for (let i = 0; i <= brailleUnicode.length / this.TWO_HUNDRED_CELLS; i++) {
        const newStr = brailleUnicode.substring(i * 300, (i + 1) * 300);
        let newPageStr = "";
        for (let j = 0; j < newStr.length / 30; j++) {
          newPageStr += newStr.substring(j * 30, (j + 1) * 30);
          if(j != (newStr.length / 30)) {
            newPageStr += "\n";
          }
        }
        if (newStr !== "") {
          this.pages.push(newPageStr);
        }
      }
    },
    async fetchTextToBraille() {
      // inkwords -> 문자 -> 엔터키 포함
      this.showSpinner();
      return await this.greatGrandParent.fetchTextToBraille("tactile", this.inkWords.replace(/[&<>"'%]/g, ''))
        .finally(() => {
          this.hideSpinner();
        });
    },
    brailleUnicode(hex) {
      return Braille.toChar(hex.toString());
    },
    async onConfirmButton() {
      const pages = [];
      let dtms;
      document.getElementById("spinner").style.zIndex = "1000";
      this.showSpinner();

      for (let i = 0; i < this.pages.length; i++) {
        if (i >= this.maxPage) {
          break;
        }
        const newPage = this.createNewPage(i);
        pages.push(newPage);
      }

      dtms = this.createNewDTMS(pages);

      this.$refs["organism-static-modal"].modal._element.style.display = "none";
      await this.greatGrandParent.newPage();
      this.removeLastPageIfBlank();
      this.bindDTMSData(dtms);
      this.greatGrandParent.isCanvasDirty = true;

      this.hideSpinner();
      document.getElementById("spinner").removeAttribute('style');

      this.$refs["organism-static-modal"].hide();
    },

    /**
     * newPage 호출하면서 생긴 빈 페이지 삭제
     */
    removeLastPageIfBlank() {
      const lastIndex = this.greatGrandParent.pages.length - 1; // 마지막 인덱스 계산
      const lastPage = this.greatGrandParent.pages[lastIndex]; // 마지막 아이템 가져오기
      const isLastPageBlank = lastPage.graphic.data.every(data => data === false);

      if (isLastPageBlank) {
        this.greatGrandParent.pages.pop();
      }
    },
    createNewPage(index) {
      const hex = this.convertToBrailleHex(this.pages[index]);
      const paddedHex = hex.padEnd(600, "0");
      return new Page("", index + 1, {name: "", data: paddedHex}, {name: "", data: "", plain: ""}, [], []);
    },
    convertToBrailleHex(unicode) {
      const hex = Braille.toHex(unicode.replace(/\n/g,''))?.replace(/\s/g, '');
      return this.dotpadsdk.changeTactileLettersGraphicDisplayText(hex);
    },
    createNewDTMS(pages) {
      return new DTMS({
        title: this.title,
        text: this.inkWords,
        lang: this.language,
        lang_option: this.grade,
        items: pages
      });
    },
    bindDTMSData(dtms) {
      this.greatGrandParent.$refs.navigation.bindDtmsData(dtms);
      this.greatGrandParent.dtms = dtms;
      this.greatGrandParent.loadPage(0);
    },
    showSpinner() {
      document.getElementById("spinner").classList.remove('d-none');
    },
    hideSpinner() {
      document.getElementById("spinner").classList.add('d-none');
    },
  }
}
</script>

<style scoped>
textarea {
  resize: none;
}

.three-hundred-cells-braille {
  height: 20.6rem;
  font-family: 'Noto Sans Symbols 2',sans-serif;
  font-size: 1.6rem;
  letter-spacing: -0.31rem;
  line-height: 1;
}

.number {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1rem;
}

.icon {
  margin: 0 20px;
}

.text-folder-name {
  width: 100px;
  height: 48px;
  background: #FFFFFF;
  border-radius: 40px;
  border:0;
  font-size: 1.2rem;
  padding-left:20px;
  margin-right: 20px;
}
.input-text-length {
  /* margin-top: -50px; */
  color: #aaabab;
  font-weight: 300;
  text-align: right;
}
.input-text-padding-top {
  padding-top: 5px;
}
.support-characters {
  padding-right: 200px;
}
</style>
